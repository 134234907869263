import { User } from '@qogita/canary-types'
import Script from 'next/script'
import { useEffect } from 'react'

import { useConsent } from '#contexts/Consent'
import { environment } from '#lib/environment.mjs'

export function GTagScript() {
  const { consent } = useConsent()

  useEffect(() => {
    if (!window.gtag) return
    if (consent.status === 'loading') return

    window.gtag('consent', 'update', {
      ad_storage: consent.value.marketing ? 'granted' : 'denied',
      analytics_storage: consent.value.performance ? 'granted' : 'denied',
      ad_user_data: consent.value.marketing ? 'granted' : 'denied',
      ad_personalization: consent.value.marketing ? 'granted' : 'denied',
    })
  }, [consent.status, consent])

  return (
    <>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${environment.NEXT_PUBLIC_GA_MEASUREMENT_ID}`}
      />
      <Script id="google-analytics">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('consent', 'default', {
            'ad_storage': 'granted',
            'analytics_storage': 'granted',
            'ad_user_data': 'granted',
            'ad_personalization': 'granted',
            wait_for_update: 500,
          });
          gtag('js', new Date());

          gtag('config', '${environment.NEXT_PUBLIC_GA_MEASUREMENT_ID}', {'allow_enhanced_conversions':true});
          gtag('config', '${environment.NEXT_PUBLIC_GOOGLE_ADS_TAG_ID}', {'allow_enhanced_conversions':true});
        `}
      </Script>
    </>
  )
}

export function gtagEvent(
  // We need this to be a string literal type, but also allow for custom events
  // eslint-disable-next-line @typescript-eslint/ban-types
  eventName: Gtag.EventNames | (string & {}),
  eventParams?: Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams,
) {
  if (!window.gtag) return

  window.gtag('event', eventName, eventParams)
}

export function gtagConversion(
  conversionId: string,
  params: Pick<Gtag.EventParams, 'value' | 'currency' | 'transaction_id'> = {},
) {
  if (!window.gtag) return

  window.gtag('event', 'conversion', {
    send_to: `${environment.NEXT_PUBLIC_GOOGLE_ADS_TAG_ID}/${conversionId}`,
    ...params,
  })
}

type UserData = Pick<User, 'email' | 'phone'>
export function gtagSetUserData({ email, phone }: UserData) {
  if (!window.gtag) return

  window.gtag('set', 'user_data', {
    email,
    // gtag recommends that if our site doesn't collect one of the fields, we should remove the field entirely rather than leaving it blank: https://support.google.com/google-ads/answer/13258081?sjid=16017382173959970421-EU
    ...(phone ? { phone_number: phone } : {}),
  })
}
