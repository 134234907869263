import '../global.css'

import {
  CanaryClient,
  CanaryClientProvider,
  HTTPError,
} from '@qogita/canary-client'
import { logError } from '@qogita/logging'
import {
  apiPlugin,
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
  storyblokInit,
} from '@storyblok/react'
import {
  HydrationBoundary,
  keepPreviousData,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import type { AppProps } from 'next/app'
import Script from 'next/script'
import { useEffect, useState } from 'react'

import { BuyerCannotCheckoutAlert } from '#components/BuyerCannotCheckoutAlert'
import { BannerPanel } from '#components/cms/BannerPanel'
import { BenefitsCard, BenefitsPanel } from '#components/cms/BenefitsPanel'
import { BrandCard, BrandsPanel } from '#components/cms/BrandsPanel'
import { CategoriesPanel, CategoryCard } from '#components/cms/CategoriesPanel'
import { Column } from '#components/cms/Column'
import { Cta } from '#components/cms/Cta'
import { FaqPanel } from '#components/cms/FaqPanel'
import { Header } from '#components/cms/Header'
import { HeaderPanel } from '#components/cms/HeaderPanel'
import { Hero } from '#components/cms/Hero'
import { HeroPanel } from '#components/cms/HeroPanel'
import { Icon } from '#components/cms/Icon'
import { IconList, IconListItem } from '#components/cms/IconList'
import { ImageBlok } from '#components/cms/Image'
import { LegalPanel } from '#components/cms/LegalPanel'
import { PeoplePanel } from '#components/cms/PeoplePanel'
import { RichText } from '#components/cms/RichText'
import { SellViaQogitaPanel } from '#components/cms/SellViaQogitaPanel'
import { StoryPanel } from '#components/cms/StoryPanel'
import { TestimonialCard } from '#components/cms/TestimonialCard'
import { TestimonialSimplePanel } from '#components/cms/TestimonialSimplePanel'
import { TestimonialsPanel } from '#components/cms/TestimonialsPanel'
import { ThreeColumnPanel } from '#components/cms/ThreeColumnPanel'
import { TwoColumnPanel } from '#components/cms/TwoColumnPanel'
import { TwoColumnPanelNew } from '#components/cms/TwoColumnPanelNew'
import { UspsPanel } from '#components/cms/UspsPanel'
import { FeatureFlagProvider } from '#components/FeatureFlagProvider'
import { RootErrorBoundary } from '#components/RootErrorBoundary'
import { Toaster } from '#components/Toast/Toaster'
import { AuthenticationProvider } from '#contexts/Authentication'
import { ConsentBanner, ConsentProvider, useConsent } from '#contexts/Consent'
import { DatadogInit } from '#lib/datadog/datadog.client'
import { environment } from '#lib/environment.mjs'
import { FetchError } from '#lib/error'
import { AnalyticsProvider } from '#lib/report/AnalyticsProvider'
import { BingScript } from '#lib/report/Bing'
import { GTagScript } from '#lib/report/Gtag'
import { MetaScript } from '#lib/report/Meta'
import { TikTokScript } from '#lib/report/TikTok'
import { PageStoryblok } from '#types/storyblok-component-types'

import { VerifiedRedirect } from '../components/pages/VerificationPage/VerifiedRedirect'

if (environment.NEXT_PUBLIC_ENABLE_MSW) {
  require('../test/initMocks')
}

/**
 * We only want to load the support widget if we're in production
 */
function SupportWidget() {
  const isVisible = environment.NEXT_PUBLIC_ENABLE_SUPPORT_WIDGET

  if (!isVisible) return null

  const HUBSPOT_SNIPPET_SRC = '//js.hs-scripts.com/139645065.js'
  return (
    <Script
      id="hs-script-loader"
      src={HUBSPOT_SNIPPET_SRC}
      strategy="lazyOnload"
    />
  )
}

function TrustpilotScript() {
  const { consent } = useConsent()

  const isConsentAccepted =
    consent.status !== 'loading' && consent.value.marketing

  const shouldLoadScript = isConsentAccepted

  return shouldLoadScript ? (
    <Script
      id="trustpilot-script-loader"
      src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
      strategy="lazyOnload"
    />
  ) : null
}

const StoryblokPage = ({ blok }: { blok: PageStoryblok }) => {
  return (
    <main {...storyblokEditable(blok)}>
      {blok.body?.map((blok) => (
        <StoryblokComponent blok={blok} key={blok._uid} />
      ))}
    </main>
  )
}

const StoryblokFallbackComponent = ({ blok }: { blok: SbBlokData }) => {
  useEffect(() => {
    logError('Storyblok component not found', { component: blok.component })
  }, [blok.component])

  return null
}

storyblokInit({
  accessToken: environment.NEXT_PUBLIC_STORYBLOK_ACCESS_TOKEN,
  use: [apiPlugin],
  components: {
    page: StoryblokPage,
    hero: Hero,
    heroPanel: HeroPanel,
    bannerPanel: BannerPanel,
    categoriesPanel: CategoriesPanel,
    categoryCard: CategoryCard,
    uspsPanel: UspsPanel,
    benefitsPanel: BenefitsPanel,
    benefitsCard: BenefitsCard,
    faqPanel: FaqPanel,
    brandsPanel: BrandsPanel,
    brand: BrandCard,
    sellViaQogitaPanel: SellViaQogitaPanel,
    testimonialsPanel: TestimonialsPanel,
    peoplePanel: PeoplePanel,
    twoColumnPanel: TwoColumnPanel,
    twoColumnPanelNew: TwoColumnPanelNew,
    threeColumnPanel: ThreeColumnPanel,
    richText: RichText,
    testimonialCard: TestimonialCard,
    testimonialSimplePanel: TestimonialSimplePanel,
    image: ImageBlok,
    cta: Cta,
    icon: Icon,
    header: Header,
    column: Column,
    iconList: IconList,
    iconListItem: IconListItem,
    headerPanel: HeaderPanel,
    storyPanel: StoryPanel,
    legalPanel: LegalPanel,
  },
  enableFallbackComponent: true,
  customFallbackComponent: StoryblokFallbackComponent,
})

const isAnalyticsEnabled = environment.NEXT_PUBLIC_ANALYTICS_ENABLED

const QogitaApp = ({ Component, pageProps }: AppProps): JSX.Element => {
  const serverAuthProps = pageProps.auth ?? { isAuthenticated: false }

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            throwOnError: true,
            placeholderData: keepPreviousData,
            retry: (failureCount, error) => {
              // There's no point retrying if the error is a 404 or 401
              if (
                (error instanceof FetchError &&
                  [404, 401].includes(error.context.statusCode)) ||
                (error instanceof HTTPError &&
                  [404, 401].includes(error.response.status))
              ) {
                return false
              }

              // Otherwise retry up to 3 times
              return failureCount < 3
            },
          },
        },
        queryCache: new QueryCache({
          onError: (error) => {
            if (
              error instanceof FetchError &&
              error.context.statusCode === 401
            ) {
              // If we get a 401, we should log the user out
              window.location.reload()
            }
          },
        }),
      }),
  )

  const [canaryClient] = useState(
    () => new CanaryClient({ prefixUrl: environment.NEXT_PUBLIC_API_BASE_URL }),
  )

  return (
    <CanaryClientProvider client={canaryClient}>
      <QueryClientProvider client={queryClient}>
        <HydrationBoundary state={pageProps.dehydratedState}>
          <AuthenticationProvider {...serverAuthProps}>
            <ConsentProvider>
              <AnalyticsProvider
                writeKey={environment.NEXT_PUBLIC_SEGMENT_WRITE_KEY}
              >
                <FeatureFlagProvider initialState={pageProps.featureFlagState}>
                  <VerifiedRedirect />
                  <RootErrorBoundary>
                    <Component {...pageProps} />
                    <Toaster />
                    <BuyerCannotCheckoutAlert />
                  </RootErrorBoundary>
                  {isAnalyticsEnabled ? (
                    <>
                      <GTagScript />
                      <MetaScript />
                      <BingScript />
                      <TikTokScript />
                    </>
                  ) : null}
                  <SupportWidget />
                  <TrustpilotScript />
                  <ConsentBanner />
                  <DatadogInit />
                </FeatureFlagProvider>
              </AnalyticsProvider>
            </ConsentProvider>
          </AuthenticationProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </HydrationBoundary>
      </QueryClientProvider>
    </CanaryClientProvider>
  )
}

export default QogitaApp
