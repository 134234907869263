import { ArrowRightIcon } from '@heroicons/react/24/outline'
import { storyblokEditable } from '@storyblok/react'
import Image from 'next/image'
import React from 'react'

import { CmsPanelErrorBoundary } from '#components/cms/CmsPanelErrorBoundary'
import { TrustBoxMicroTrustComboScore } from '#components/Trustpilot/MicroTrustComboScore'
import { TestimonialsPanelStoryblok } from '#types/storyblok-component-types'

import { Anchor } from './Anchor'
import { storyblokImageLoader } from './storyblokImageLoader'

const TestimonialsPanelComponent = ({
  blok,
}: {
  blok: TestimonialsPanelStoryblok
}) => {
  const [cta] = blok.cta || []
  return (
    <div {...storyblokEditable(blok)}>
      <div className="mx-auto mb-8 flex w-full max-w-5xl flex-col gap-8 px-4 pt-8 md:px-6 md:pt-12">
        <h2 className="q-text-header-4xl self-center text-balance">
          {blok.heading}
        </h2>
        <TrustBoxMicroTrustComboScore />
      </div>
      <div className="bg-white pb-8 pt-10 text-white md:pb-12">
        <div className="bg-marketing-primary-dark">
          <div className="mx-auto flex max-w-5xl flex-col items-center lg:flex-row lg:items-stretch">
            <div className="-mt-8 w-full max-w-2xl px-4 md:pl-4 md:pr-0 lg:-mb-8 lg:w-96 lg:max-w-xs lg:flex-none">
              <div className="relative aspect-[2/1] h-full md:-mx-8 lg:mx-0 lg:aspect-auto">
                <div>
                  <Image
                    loader={storyblokImageLoader}
                    src={blok.testimonialImage.filename}
                    alt={blok.testimonialImage.alt ?? ''}
                    fill
                    className="absolute inset-0 h-full w-full rounded-lg bg-gray-800 object-cover shadow-2xl"
                  />
                </div>
              </div>
            </div>
            <div className="w-full max-w-2xl px-4 py-6 md:pb-11 md:pt-11 lg:max-w-none lg:flex-auto lg:pl-16 lg:pt-20 xl:pr-0">
              <figure className="relative isolate">
                <blockquote className="q-text-body-base md:text-lg md:font-light">
                  <p>&quot;{blok.testimonialBody}&quot;</p>
                </blockquote>
                <figcaption className="mt-8 text-base">
                  <div className="q-text-body-base-bold">
                    {blok.testimonialName}
                  </div>
                  <div className="q-text-body-sm">
                    {blok.testimonialJobTitle}
                  </div>
                  {cta ? (
                    <Anchor
                      className="mt-6 border-none text-white hover:text-white"
                      blok={cta}
                      color="primary"
                      appearance="text"
                      size="medium"
                    >
                      <Anchor.Icon>
                        <ArrowRightIcon />
                      </Anchor.Icon>
                    </Anchor>
                  ) : null}
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export const TestimonialsPanel = ({
  blok,
}: {
  blok: TestimonialsPanelStoryblok
}) => {
  return (
    <CmsPanelErrorBoundary>
      <TestimonialsPanelComponent blok={blok} />
    </CmsPanelErrorBoundary>
  )
}
