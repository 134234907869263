import { load } from '@fingerprintjs/botd'

// This is the list of userAgents MixPanel uses to block web crawlers' traffic
const CRAWLER_USERAGENT_STRINGS = [
  'ahrefsbot',
  'amazonbot',
  'baiduspider',
  'bingbot',
  'bingpreview',
  'dataforseo-bot',
  'facebookexternal',
  'petalbot',
  'pinterest',
  'screaming frog',
  'yahoo! slurp',
  'yandexbot',

  // a whole bunch of goog-specific crawlers
  // https://developers.google.com/search/docs/advanced/crawling/overview-google-crawlers
  'adsbot-google',
  'apis-google',
  'duplexweb-google',
  'feedfetcher-google',
  'google favicon',
  'google web preview',
  'google-read-aloud',
  'googlebot',
  'googleweblight',
  'mediapartners-google',
  'storebot-google',
]

const getIsCrawler = (userAgent: string) => {
  return CRAWLER_USERAGENT_STRINGS.some((uaString) =>
    userAgent.toLowerCase().includes(uaString),
  )
}

export const getIsBotUser = async () => {
  const isCrawler = getIsCrawler(navigator.userAgent)

  if (isCrawler) {
    return true
  }

  return load()
    .then((botd) => botd.detect())
    .then(({ bot }) => {
      return bot
    })
    .catch((error) => {
      console.error(error)
      return false
    })
}
