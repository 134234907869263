import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { zodResolver } from '@hookform/resolvers/zod'
import { logWarning } from '@qogita/logging'
import { Button, cn, Modal, Switch } from '@qogita/ui'
import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { Slot } from '@radix-ui/react-slot'
import cookie from 'cookie'
import { useRouter } from 'next/router'
import {
  ComponentPropsWithoutRef,
  createContext,
  ElementRef,
  forwardRef,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Controller, useForm } from 'react-hook-form'

import { NextAnchor } from '#components/NextAnchor'
import {
  ConsentValue,
  consentValueSchema,
  COOKIE_CONSENT_KEY,
} from '#lib/consent'
import { ONE_YEAR_IN_SECONDS } from '#lib/time'

import { setBrowserCookie } from '../lib/cookie'
import { useAuthentication } from './Authentication'

type Consent =
  | {
      status: 'loading'
    }
  | {
      status: 'default'
      value: ConsentValue
    }
  | {
      status: 'ready'
      value: ConsentValue
    }

type ConsentContext = {
  consent: Consent
  acceptAll: () => void
  updateConsent: (value: ConsentValue) => void
  hasConsent: (consentType: keyof ConsentValue) => boolean
}

const ConsentContext = createContext<ConsentContext | undefined>(undefined)

export function useConsent() {
  const context = useContext(ConsentContext)
  if (context === undefined) {
    throw new Error('useConsent must be used within a ConsentProvider')
  }
  return context
}

// Default consent to be used prior to the user interacting with our cookie banner.
const defaultConsent = {
  status: 'default',
  value: { functional: true, performance: true, marketing: true },
} as const

const fullyAcceptedConsent = {
  status: 'ready',
  value: { functional: true, performance: true, marketing: true },
} as const

export function ConsentProvider({ children }: { children: ReactNode }) {
  // For logged out users we show a cookie banner and we store their consent preferences in a cookie
  // But for logged in users we override this setting and always allow all cookies
  // So if status is logged in, we return a hardcoded `fullyAcceptedConsent` value instead of whatever's in the cookie
  const [rawConsent, setConsent] = useState<Consent>({ status: 'loading' })
  const { isAuthenticated } = useAuthentication()
  const consent = isAuthenticated ? fullyAcceptedConsent : rawConsent

  useEffect(() => {
    const consentCookie = cookie.parse(document.cookie)[COOKIE_CONSENT_KEY]

    if (!consentCookie) {
      return setConsent(defaultConsent)
    }

    try {
      const parsedConsentValue = consentValueSchema.parse(
        JSON.parse(consentCookie),
      )
      return setConsent({ status: 'ready', value: parsedConsentValue })
    } catch {
      // We don't really care why we have a malformed cookie
      // it could be manually modified, or an old version, either way we'll need to ask
      // for consent again
      return setConsent(defaultConsent)
    }
  }, [])

  const router = useRouter()

  const updateConsent = (value: ConsentValue) => {
    setConsent({ status: 'ready', value })
    setBrowserCookie(COOKIE_CONSENT_KEY, JSON.stringify(value), {
      maxAge: ONE_YEAR_IN_SECONDS,
      path: '/',
    })
    router.reload()
  }

  function hasConsent(consentType: keyof ConsentValue) {
    if (consent.status === 'loading') {
      return false
    }

    return consent.value[consentType]
  }

  const acceptAll = () => {
    updateConsent({
      functional: true,
      performance: true,
      marketing: true,
    })
  }

  return (
    <ConsentContext.Provider
      value={{ consent, acceptAll, updateConsent, hasConsent }}
    >
      {children}
    </ConsentContext.Provider>
  )
}

export function ConsentBanner() {
  const { consent, acceptAll } = useConsent()

  if (consent.status !== 'default') {
    return null
  }

  return (
    <div className="fixed bottom-0 left-0 right-0 z-10 flex justify-center md:px-4 md:pb-12">
      <div className="max-w-site-content flex flex-col gap-6 border bg-white px-6 py-4 shadow lg:flex-row lg:items-center">
        <p className="q-text-body-xs text-gray-700">
          By clicking “Accept All” you agree to the storing of cookies on your
          device to enhance site navigation, analyze site usage and assist in
          our marketing efforts. You can choose “Cookie Settings” for more
          information and to customize your settings and disable all or some
          non-essential cookies.
        </p>
        <div className="flex shrink-0 flex-col-reverse justify-end gap-3 md:flex-row">
          <ConsentModal>
            <ConsentModalTrigger asChild>
              <Button color="primary" appearance="outlined">
                Cookie settings
              </Button>
            </ConsentModalTrigger>
            <CookieSettingsModelContent />
          </ConsentModal>
          <Button onClick={acceptAll}>Accept all</Button>
        </div>
      </div>
    </div>
  )
}

export function CookieSettingsModelContent() {
  const { closeModal } = useModal()
  const { consent, updateConsent, acceptAll } = useConsent()

  const defaultValues =
    consent.status === 'ready'
      ? consent.value
      : ({
          functional: false,
          performance: false,
          marketing: false,
        } satisfies ConsentValue)

  const { handleSubmit, control } = useForm<ConsentValue>({
    defaultValues,
    resolver: zodResolver(consentValueSchema),
  })

  return (
    <Modal.Content>
      <Modal.Header>
        <Modal.Title>Cookie settings</Modal.Title>
      </Modal.Header>
      {consent.status === 'loading' ? null : (
        <form
          onSubmit={handleSubmit(
            (updatedConsent) => {
              updateConsent(updatedConsent)
              closeModal()
            },
            (error) => {
              logWarning('Consent cookie settings validation failed', error)
            },
          )}
        >
          <Modal.Body>
            <p className="q-text-body-sm border-b pb-6 text-gray-700">
              When you visit any website, it may store or retrieve information
              on your browser, mostly in the form of cookies. This information
              might be about you, your preferences or your device and is mostly
              used to make the site work as you expect it to. The information
              does not usually directly identify you, but it can give you a more
              personalized web experience. Because we respect your right to
              privacy, you can choose not to allow some types of cookies. Click
              on the different category headings to find out more and change our
              default settings. However, blocking some types of cookies may
              impact your experience of the site and the services we are able to
              offer.{' '}
              <NextAnchor
                color="primary"
                appearance="text"
                href="/legal/cookies/"
              >
                Learn more
              </NextAnchor>
            </p>
            <h3 className="q-text-body-base-bold my-6">
              Manage consent preferences
            </h3>
            <Accordion type="single" collapsible>
              <AccordionItem value="essential">
                <div className="flex items-center justify-between">
                  <AccordionTrigger>
                    <AccordionIndicator />
                    Essential cookies
                  </AccordionTrigger>
                  <p className="text-gray-500">Always active</p>
                </div>
                <AccordionContent className="q-text-body-sm px-6 text-gray-700">
                  These cookies are necessary for the website to function and
                  cannot be switched off in our systems. They are usually only
                  set in response to actions made by you which amount to a
                  request for services, such as setting your privacy
                  preferences, logging in or filling in forms. You can set your
                  browser to block or alert you about these cookies, but some
                  parts of the site will not then work. These cookies do not
                  store any personally identifiable information.
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="performance">
                <div className="flex items-center justify-between">
                  <AccordionTrigger id="performance">
                    <AccordionIndicator />
                    Performance cookies
                  </AccordionTrigger>
                  <Controller
                    control={control}
                    name="performance"
                    render={({ field }) => (
                      <Switch
                        aria-labelledby="performance"
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    )}
                  />
                </div>
                <AccordionContent className="q-text-body-sm px-6 text-gray-700">
                  These cookies allow us to count visits and traffic sources so
                  we can measure and improve the performance of our site. They
                  help us to know which pages are the most and least popular and
                  see how visitors move around the site. All information these
                  cookies collect is aggregated and therefore anonymous. If you
                  do not allow these cookies we will not know when you have
                  visited our site, and will not be able to monitor its
                  performance.
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="functional">
                <div className="flex items-center justify-between">
                  <AccordionTrigger id="functional">
                    <AccordionIndicator />
                    Functional cookies
                  </AccordionTrigger>
                  <Controller
                    control={control}
                    name="functional"
                    render={({ field }) => (
                      <Switch
                        aria-labelledby="functional"
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    )}
                  />
                </div>
                <AccordionContent className="q-text-body-sm px-6 text-gray-700">
                  These cookies enable the website to provide enhanced
                  functionality and personalisation. They may be set by us or by
                  third party providers whose services we have added to our
                  pages. If you do not allow these cookies then some or all of
                  these services may not function properly.
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="marketing">
                <div className="flex items-center justify-between">
                  <AccordionTrigger id="marketing">
                    <AccordionIndicator />
                    Marketing cookies
                  </AccordionTrigger>
                  <Controller
                    control={control}
                    name="marketing"
                    render={({ field }) => (
                      <Switch
                        aria-labelledby="marketing"
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    )}
                  />
                </div>
                <AccordionContent className="q-text-body-sm px-6 text-gray-700">
                  These cookies may be set through our site by our advertising
                  partners. They may be used by those companies to build a
                  profile of your interests and show you relevant adverts on
                  other sites. They do not store directly personal information,
                  but are based on uniquely identifying your browser and
                  internet device. If you do not allow these cookies, you will
                  experience less targeted advertising.
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </Modal.Body>
          <Modal.Footer>
            <Modal.Actions className="flex-col-reverse">
              <Button
                color="primary"
                appearance="outlined"
                type="submit"
                size="medium"
              >
                Confirm choices
              </Button>
              <Button
                color="primary"
                appearance="contained"
                size="medium"
                onClick={() => {
                  acceptAll()
                  closeModal()
                }}
              >
                Accept all
              </Button>
            </Modal.Actions>
          </Modal.Footer>
        </form>
      )}
    </Modal.Content>
  )
}

const Accordion = AccordionPrimitive.Root

const AccordionItem = forwardRef<
  ElementRef<typeof AccordionPrimitive.Item>,
  ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item ref={ref} className={className} {...props} />
))
AccordionItem.displayName = 'AccordionItem'

const AccordionTrigger = forwardRef<
  ElementRef<typeof AccordionPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Header className="flex">
    <AccordionPrimitive.Trigger
      ref={ref}
      className={cn(
        'flex flex-1 items-center justify-between gap-3 py-2 transition-all [&[data-state=open]>svg]:rotate-180',
        className,
      )}
      {...props}
    >
      {children}
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
))
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName

const AccordionIndicator = forwardRef<
  ElementRef<'svg'>,
  ComponentPropsWithoutRef<'svg'>
>(({ className, ...props }, ref) => (
  <ChevronDownIcon
    ref={ref}
    className={cn(
      'h-5 w-5 shrink-0 text-gray-400 transition-transform duration-200',
      className,
    )}
    {...props}
  />
))
AccordionIndicator.displayName = 'AccordionIndicator'

const AccordionContent = forwardRef<
  ElementRef<typeof AccordionPrimitive.Content>,
  ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className={cn(
      'data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down overflow-hidden',
      className,
    )}
    {...props}
  >
    <div className="pb-4 pt-0">{children}</div>
  </AccordionPrimitive.Content>
))
AccordionContent.displayName = AccordionPrimitive.Content.displayName

type ModalContext = {
  isOpen: boolean
  openModal: () => void
  closeModal: () => void
}
const ModalContext = createContext<ModalContext | null>(null)

function useModal() {
  const context = useContext(ModalContext)
  if (!context) {
    throw new Error(
      'Modal compound components cannot be rendered outside the ModalProvider component',
    )
  }
  return context
}

export function ConsentModal({ children }: { children: ReactNode }) {
  const [isOpen, setIsOpen] = useState(false)
  const closeModal = () => setIsOpen(false)
  const openModal = () => setIsOpen(true)

  return (
    <ModalContext.Provider value={{ isOpen, openModal, closeModal }}>
      <Modal
        open={isOpen}
        onOpenChange={(isOpen) => (isOpen ? openModal() : closeModal())}
      >
        {children}
      </Modal>
    </ModalContext.Provider>
  )
}

export function ConsentModalTrigger({
  asChild,
  ...props
}: Omit<ComponentPropsWithoutRef<typeof Button>, 'onClick'> & {
  asChild?: true | undefined
}) {
  const { openModal } = useModal()
  const Component = asChild ? Slot : 'button'
  return <Component {...props} onClick={openModal} />
}
