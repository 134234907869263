import {
  CanaryClient,
  GetCartDroppedLinesSearchParams,
  GetCartLinesSearchParams,
  GetCartsSearchParams,
  useCanaryClient,
} from '@qogita/canary-client'
import {
  CartAllocationLineRequest,
  CartLineRequest,
  PatchedCartAllocationLineRequest,
  PatchedCartLinePatchRequest,
} from '@qogita/canary-types'
import {
  queryOptions,
  useIsMutating,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

import { useAddToCartToast } from '#components/AddToCartToast'
import { useAuthentication } from '#contexts/Authentication'
import { EventOrigin, useTrackEvent } from '#lib/report/tracking'

import { getCheckoutQueries } from './checkout-queries'
import { getUserQueries } from './user-queries'
import { replaceUndefinedValuesWithNull } from './utils'

export function getCartQueries(canaryClient: CanaryClient) {
  const queries = {
    all: () => ['carts'] as const,
    allLists: () => [...queries.all(), 'list'] as const,
    list: (params: GetCartsSearchParams = {}) =>
      queryOptions({
        queryKey: [
          ...queries.allLists(),
          replaceUndefinedValuesWithNull(params),
        ] as const,
        queryFn: () => canaryClient.getCarts(params),
      }),
    allDetails: () => [...queries.all(), 'detail'] as const,
    detail: (qid: string) =>
      queryOptions({
        queryKey: [...queries.allDetails(), qid] as const,
        queryFn: () => canaryClient.getCart(qid),
      }),
    allLines: (qid: string) =>
      [...queries.detail(qid).queryKey, 'lines'] as const,
    linesList: (qid: string, params: GetCartLinesSearchParams = {}) =>
      queryOptions({
        queryKey: [
          ...queries.allLines(qid),
          'list',
          replaceUndefinedValuesWithNull(params),
        ] as const,
        queryFn: () => canaryClient.getCartLines(qid, params),
      }),
    lineDetail: ({ cartQid, lineQid }: { cartQid: string; lineQid: string }) =>
      [...queries.allLines(cartQid), 'detail', lineQid] as const,
    allDroppedLines: (qid: string) =>
      [...queries.detail(qid).queryKey, 'droppedLines'] as const,
    droppedLinesList: (
      qid: string,
      params: GetCartDroppedLinesSearchParams = {},
    ) =>
      queryOptions({
        queryKey: [
          ...queries.allDroppedLines(qid),
          'list',
          replaceUndefinedValuesWithNull(params),
        ] as const,
        queryFn: () => canaryClient.getCartDroppedLines(qid, params),
      }),
    allAllocations: (qid: string) =>
      [...queries.detail(qid).queryKey, 'allocations'] as const,
    allocationsList: (qid: string) =>
      queryOptions({
        queryKey: [...queries.allAllocations(qid), 'allocations'] as const,
        queryFn: () => canaryClient.getCartAllocations(qid),
      }),
    allocationDetail: ({
      cartQid,
      allocationQid,
    }: {
      cartQid: string
      allocationQid: string
    }) =>
      [...queries.allAllocations(cartQid), 'detail', allocationQid] as const,
    allAllocationLines: ({
      cartQid,
      allocationQid,
    }: {
      cartQid: string
      allocationQid: string
    }) =>
      [
        ...queries.allocationDetail({ cartQid, allocationQid }),
        'lines',
      ] as const,
    allocationLineDetail: ({
      cartQid,
      allocationQid,
      lineQid,
    }: {
      cartQid: string
      allocationQid: string
      lineQid: string
    }) =>
      [
        ...queries.allAllocationLines({ cartQid, allocationQid }),
        'detail',
        lineQid,
      ] as const,
  }
  return queries
}

export function useActiveCart() {
  const { isAuthenticated } = useAuthentication()
  const canaryClient = useCanaryClient()
  const userQueries = getUserQueries(canaryClient)
  const cartQueries = getCartQueries(canaryClient)

  const { data: activeCartQid = '' } = useQuery({
    ...userQueries.detail(),
    enabled: isAuthenticated,
    select: (user) => user.activeCartQid,
  })

  return useQuery({
    ...cartQueries.detail(activeCartQid),
    enabled: isAuthenticated && Boolean(activeCartQid),
    refetchOnMount: false,
  })
}

export function useCreateCartLine({
  cartQid,
  gtin,
}: {
  cartQid: string
  gtin: string
}) {
  const canaryClient = useCanaryClient()
  const queryClient = useQueryClient()
  const cartQueries = getCartQueries(canaryClient)
  const { trackProductAdded } = useTrackEvent()
  const { toast } = useAddToCartToast()

  return useMutation({
    mutationKey: [...cartQueries.allLines(cartQid), 'create'] as const,
    mutationFn: (data: Omit<CartLineRequest, 'gtin'>) =>
      canaryClient.createCartLine({ cartQid, data: { ...data, gtin } }),
    onSuccess: async (cartLine, request) => {
      trackProductAdded({
        cartId: cartQid,
        quantity: request.quantity,
        product: {
          brand: cartLine.variant.brand.name,
          category: cartLine.variant.category.name,
          currency: cartLine.priceCurrency,
          name: cartLine.variant.name,
          price: cartLine.price,
          productId: cartLine.variant.gtin,
        },
      })
      await queryClient.invalidateQueries()
      toast({
        variant: cartLine.variant,
        quantity: request.quantity,
        isDeal: request.dealId !== undefined,
        price: cartLine.price,
        priceCurrency: cartLine.priceCurrency,
      })
    },
  })
}

export function useUpdateCartLine({
  cartQid,
  lineQid,
}: {
  cartQid: string
  lineQid: string
}) {
  const canaryClient = useCanaryClient()
  const queryClient = useQueryClient()
  const cartQueries = getCartQueries(canaryClient)
  return useMutation({
    mutationKey: [
      ...cartQueries.lineDetail({ cartQid, lineQid }),
      'update',
    ] as const,
    mutationFn: (data: PatchedCartLinePatchRequest) =>
      canaryClient.updateCartLine({ cartQid, lineQid, data }),
    onSuccess: async () => {
      await queryClient.invalidateQueries()
    },
  })
}

export function useDeleteCartLine({
  cartQid,
  lineQid,
}: {
  cartQid: string
  lineQid: string
}) {
  const canaryClient = useCanaryClient()
  const queryClient = useQueryClient()
  const cartQueries = getCartQueries(canaryClient)
  return useMutation({
    mutationKey: [
      ...cartQueries.lineDetail({ cartQid, lineQid }),
      'delete',
    ] as const,
    mutationFn: () => canaryClient.deleteCartLine({ cartQid, lineQid }),
    onSuccess: async () => {
      await queryClient.invalidateQueries()
    },
  })
}

export function useCreateCartAllocationLine({
  cartQid,
  allocationQid = '',
  gtin,
}: {
  cartQid: string
  allocationQid?: string
  gtin: string
}) {
  const canaryClient = useCanaryClient()
  const queryClient = useQueryClient()
  const cartQueries = getCartQueries(canaryClient)
  const { trackProductAdded } = useTrackEvent()
  const { toast } = useAddToCartToast()

  return useMutation({
    mutationKey: [
      ...cartQueries.allAllocationLines({ cartQid, allocationQid }),
      'create',
    ] as const,
    mutationFn: (data: Omit<CartAllocationLineRequest, 'gtin'>) => {
      if (!allocationQid) {
        throw new Error(
          `Tried to add to an allocation without an allocationQid`,
        )
      }
      return canaryClient.createCartAllocationLine({
        cartQid,
        allocationQid,
        data: { ...data, gtin },
      })
    },
    onSuccess: async ({ allocationLine }, request) => {
      trackProductAdded({
        cartId: cartQid,
        quantity: request.quantity,
        product: {
          brand: allocationLine.variant.brand.name,
          category: allocationLine.variant.category.name,
          currency: allocationLine.priceCurrency,
          name: allocationLine.variant.name,
          price: allocationLine.price,
          productId: allocationLine.variant.gtin,
        },
      })
      await queryClient.invalidateQueries()
      toast({
        variant: allocationLine.variant,
        quantity: request.quantity,
        isDeal: request.dealId !== undefined,
        price: allocationLine.price,
        priceCurrency: allocationLine.priceCurrency,
      })
    },
  })
}

export function useUpdateCartAllocationLine({
  cartQid,
  allocationQid,
  lineQid,
}: {
  cartQid: string
  allocationQid: string
  lineQid: string
}) {
  const canaryClient = useCanaryClient()
  const queryClient = useQueryClient()
  const cartQueries = getCartQueries(canaryClient)
  return useMutation({
    mutationKey: [
      ...cartQueries.allocationLineDetail({ cartQid, allocationQid, lineQid }),
      'update',
    ] as const,
    mutationFn: (data: PatchedCartAllocationLineRequest) =>
      canaryClient.updateCartAllocationLine({
        cartQid,
        allocationQid,
        lineQid,
        data,
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries()
    },
  })
}

export function useDeleteCartAllocationLine({
  cartQid,
  allocationQid,
  lineQid,
}: {
  cartQid: string
  allocationQid: string
  lineQid: string
}) {
  const canaryClient = useCanaryClient()
  const queryClient = useQueryClient()
  const cartQueries = getCartQueries(canaryClient)
  return useMutation({
    mutationKey: [
      ...cartQueries.allocationLineDetail({ cartQid, allocationQid, lineQid }),
      'delete',
    ] as const,
    mutationFn: () =>
      canaryClient.deleteCartAllocationLine({
        cartQid,
        allocationQid,
        lineQid,
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries()
    },
  })
}

export function useOptimizeCartMutation(qid: string) {
  const canaryClient = useCanaryClient()
  const queryClient = useQueryClient()
  const cartQueries = getCartQueries(canaryClient)
  const checkoutQueries = getCheckoutQueries(canaryClient)

  return useMutation({
    mutationKey: [...cartQueries.detail(qid).queryKey, 'optimize'] as const,
    mutationFn: () =>
      canaryClient.optimizeCart(qid, { optimizerStrategy: 'FIXED_PRICE' }),
    onSuccess: async (checkout) => {
      queryClient.setQueryData(
        checkoutQueries.detail(checkout.qid).queryKey,
        checkout,
      )
      await queryClient.invalidateQueries()
    },
  })
}

export function useEmptyCart(qid: string) {
  const canaryClient = useCanaryClient()
  const queryClient = useQueryClient()
  const cartQueries = getCartQueries(canaryClient)
  return useMutation({
    mutationFn: () => canaryClient.emptyCart(qid),
    onSuccess: async (updatedCart) => {
      queryClient.setQueryData(cartQueries.detail(qid).queryKey, updatedCart)
      await queryClient.invalidateQueries()
    },
  })
}

export function useUploadCart({
  qid,
  origin,
}: {
  qid: string
  origin: EventOrigin
}) {
  const queryClient = useQueryClient()
  const canaryClient = useCanaryClient()
  return useMutation({
    mutationFn: ({ fileKey }: { fileKey: string }) =>
      canaryClient.uploadCart(qid, { fileKey, origin }),
    onSuccess: async () => {
      await queryClient.invalidateQueries()
    },
  })
}

/**
 * Returns true if any kind of mutation is having an effect on the cart
 */
export function useIsCartMutating(qid: string) {
  const canaryClient = useCanaryClient()
  const cartQueries = getCartQueries(canaryClient)

  return (
    useIsMutating({
      mutationKey: cartQueries.detail(qid).queryKey,
    }) > 0
  )
}
