import { CanaryClient, useCanaryClient } from '@qogita/canary-client'
import {
  AbaBankCreateRequest,
  IbanBankCreateRequest,
  PatchedCurrentUserRequest,
  SortCodeBankCreateRequest,
  UserCreateRequest,
} from '@qogita/canary-types'
import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

import { useAuthentication } from '#contexts/Authentication'
import { isDisallowedCurrency } from '#lib/currency'
import { DisallowedUserCurrencyError } from '#lib/error'

export function getUserQueries(canaryClient: CanaryClient) {
  const queries = {
    all: () => ['user'] as const,
    detail: () =>
      queryOptions({
        queryKey: queries.all(),
        queryFn: async () => {
          const user = await canaryClient.getUser()
          if (isDisallowedCurrency(user.currency)) {
            throw new DisallowedUserCurrencyError(
              'The user currency is now allowed.',
              { currency: user.currency },
            )
          }
          return user
        },
        retry: (failureCount, error) => {
          // If a DisallowedUserCurrencyError happens, retrying won't be helpful because the next two retries are expected to fail. So, we'll return false instead.
          if (error instanceof DisallowedUserCurrencyError) {
            return false
          }

          // The default retry count from React Query is 3. Since React Query does not export this default, we will leave it fixed.
          return failureCount < 3
        },
      }),
    debtor: () =>
      queryOptions({
        queryKey: [...queries.all(), 'debtor'] as const,
        queryFn: () => canaryClient.getUserDebtor(),
      }),
    groups: () =>
      queryOptions({
        queryKey: [...queries.all(), 'groups'] as const,
        queryFn: () => canaryClient.getUserGroups(),
      }),
    bank: () => [...queries.all(), 'bank'] as const,
    bankInfo: () =>
      queryOptions({
        queryKey: [...queries.bank(), 'info'] as const,
        queryFn: () => canaryClient.getUserBankInfo(),
      }),
  }
  return queries
}

export function useUser() {
  const { isAuthenticated } = useAuthentication()
  const canaryClient = useCanaryClient()
  const userQueries = getUserQueries(canaryClient)

  return useQuery({
    ...userQueries.detail(),
    enabled: isAuthenticated,
    throwOnError: false,
  })
}

export function useCreateUserMutation() {
  const canaryClient = useCanaryClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: UserCreateRequest) => canaryClient.createUser(data),
    onSuccess: async () => {
      await queryClient.invalidateQueries()
    },
  })
}

export function useUpdateUser() {
  const canaryClient = useCanaryClient()
  const queryClient = useQueryClient()
  const userQueries = getUserQueries(canaryClient)

  return useMutation({
    mutationFn: (data: PatchedCurrentUserRequest) =>
      canaryClient.updateUser(data),
    onSuccess: (updatedUser) => {
      queryClient.setQueryData(userQueries.detail().queryKey, updatedUser)
      queryClient.invalidateQueries()
    },
  })
}

export function useCreateUserBankAbaMutation() {
  const canaryClient = useCanaryClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: AbaBankCreateRequest) =>
      canaryClient.createUserBankAba(data),
    onSuccess: async () => {
      await queryClient.invalidateQueries()
    },
  })
}

export function useCreateUserBankIbanMutation() {
  const canaryClient = useCanaryClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: IbanBankCreateRequest) =>
      canaryClient.createUserBankIban(data),
    onSuccess: async () => {
      await queryClient.invalidateQueries()
    },
  })
}

export function useCreateUserBankSortCodeMutation() {
  const canaryClient = useCanaryClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: SortCodeBankCreateRequest) =>
      canaryClient.createUserBankSortCode(data),
    onSuccess: async () => {
      await queryClient.invalidateQueries()
    },
  })
}
