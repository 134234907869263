import Link from 'next/link'

import { useTrackEvent } from '#lib/report/tracking'

export const MastheadSellViaQogitaLink = ({
  className,
}: {
  className?: string
}) => {
  const { trackSellViaQogitaLinkClicked } = useTrackEvent()

  return (
    <Link
      href="https://sellers.qogita.com"
      className={className}
      target="_blank"
      onClick={() => {
        trackSellViaQogitaLinkClicked({
          origin: 'Masthead',
        })
      }}
    >
      <p className="q-text-body-sm-medium whitespace-nowrap leading-tight tracking-wide text-gray-900">
        Sell Via Qogita
      </p>
    </Link>
  )
}
