import { StoryblokComponent, storyblokEditable } from '@storyblok/react'

import { CenteredContent } from '#components/Marketing/CenteredContent'
import { TwoColumnPanelStoryblok } from '#types/storyblok-component-types'

import { CmsPanelErrorBoundary } from './CmsPanelErrorBoundary'

function TwoColumnPanelComponent({ blok }: { blok: TwoColumnPanelStoryblok }) {
  return (
    <div {...storyblokEditable(blok)}>
      <CenteredContent>
        <div className="flex flex-col gap-6 py-8 md:gap-8 md:py-12">
          <div className="grid grid-cols-1 md:grid-cols-3">
            <h2 className="q-text-header-4xl col-span-2 text-balance text-left">
              {blok.heading}
            </h2>
          </div>
          <div className="grid grid-cols-1 gap-6 md:grid-cols-3 md:gap-8">
            <div className="col-span-2 flex flex-col items-start gap-6 md:gap-8">
              {blok.columnLeft?.map((blok) => (
                <StoryblokComponent key={blok._uid} blok={blok} />
              ))}
            </div>
            <div className="col-span-1 flex flex-col items-start gap-6 md:gap-8">
              {blok.columnRight?.map((blok) => (
                <StoryblokComponent key={blok._uid} blok={blok} />
              ))}
            </div>
          </div>
        </div>
      </CenteredContent>
    </div>
  )
}

export const TwoColumnPanel = ({ blok }: { blok: TwoColumnPanelStoryblok }) => {
  return (
    <CmsPanelErrorBoundary>
      <TwoColumnPanelComponent blok={blok} />
    </CmsPanelErrorBoundary>
  )
}
