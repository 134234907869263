import { Anchor } from '@qogita/ui'
import Link, { LinkProps } from 'next/link'
import { ComponentPropsWithRef } from 'react'

export function NextAnchor({
  color,
  size,
  appearance,
  children,
  ...props
}: Omit<ComponentPropsWithRef<typeof Anchor>, 'asChild' | 'href'> & LinkProps) {
  return (
    <Anchor asChild color={color} appearance={appearance} size={size}>
      <Link {...props}>{children}</Link>
    </Anchor>
  )
}

NextAnchor.Icon = Anchor.Icon
