import {
  CanaryClient,
  GetOrderClaimsSearchParams,
  GetOrderLinesSearchParams,
  GetOrderSalesSearchParams,
  GetOrdersSearchParams,
  useCanaryClient,
} from '@qogita/canary-client'
import {
  BuyerOrderStatus,
  OrderSaleIssueInputRequest,
} from '@qogita/canary-types'
import {
  queryOptions,
  skipToken,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import { getCartQueries } from './cart-queries'
import { getUserQueries } from './user-queries'
import { replaceUndefinedValuesWithNull } from './utils'

export function getOrderQueries(canaryClient: CanaryClient) {
  const queries = {
    all: () => ['orders'] as const,
    allLists: () => [...queries.all(), 'list'] as const,
    list: (params: GetOrdersSearchParams = {}) =>
      queryOptions({
        queryKey: [
          ...queries.allLists(),
          replaceUndefinedValuesWithNull(params),
        ] as const,
        queryFn: () => canaryClient.getOrders(params),
      }),
    allDetails: () => [...queries.all(), 'detail'] as const,
    detail: (qid: string) =>
      queryOptions({
        queryKey: [...queries.allDetails(), qid] as const,
        queryFn: () => canaryClient.getOrder(qid),
      }),
    detailOptionalQid: (qid?: string) =>
      queryOptions({
        queryKey: [...queries.allDetails(), qid ?? null] as const,
        queryFn: qid ? () => canaryClient.getOrder(qid) : skipToken,
      }),
    allSales: (qid: string) =>
      [...queries.detail(qid).queryKey, 'sales'] as const,
    sales: (qid: string, params: GetOrderSalesSearchParams = {}) =>
      queryOptions({
        queryKey: [
          ...queries.allSales(qid),
          replaceUndefinedValuesWithNull(params),
        ] as const,
        queryFn: () => canaryClient.getOrderSales(qid, params),
      }),
    allLines: (qid: string) =>
      [...queries.detail(qid).queryKey, 'lines'] as const,
    lines: (qid: string, params: GetOrderLinesSearchParams = {}) =>
      queryOptions({
        queryKey: [
          ...queries.allLines(qid),
          replaceUndefinedValuesWithNull(params),
        ] as const,
        queryFn: () => canaryClient.getOrderLines(qid, params),
      }),
    allClaims: (qid: string) =>
      [...queries.detail(qid).queryKey, 'claims'] as const,
    claims: (qid: string, params: GetOrderClaimsSearchParams = {}) =>
      queryOptions({
        queryKey: [
          ...queries.allClaims(qid),
          replaceUndefinedValuesWithNull(params),
        ] as const,
        queryFn: () => canaryClient.getOrderClaims(qid, params),
      }),
  }

  return queries
}

export const BUYER_ORDER_STATUS_OPTIONS = [
  'CHECKOUT',
  'FINANCED',
  'AWAITING_PAYMENT',
  'PAID_PREPAID',
  'IN_PROGRESS',
  'SHIPPED',
  'PAID_FINANCED',
  'EXPIRED',
  'CANCELLED',
  'PAYMENT_OVERDUE',
  'DEFAULTED',
] as const satisfies ReadonlyArray<BuyerOrderStatus>
export type GetOrdersSortOption = NonNullable<GetOrdersSearchParams['order']>
type RemoveMinuses<T extends string> = T extends `-${infer U}` ? U : T
export type GetOrdersSortField = RemoveMinuses<GetOrdersSortOption>
export const GET_ORDERS_SORT_OPTIONS = [
  'fid',
  '-fid',
  'total',
  '-total',
  'submitted_at',
  '-submitted_at',
] as const satisfies ReadonlyArray<GetOrdersSortOption>

export function useCreateOrderIssue(qid: string) {
  const canaryClient = useCanaryClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: OrderSaleIssueInputRequest) =>
      canaryClient.createOrderIssue(qid, data),
    onSuccess: async () => {
      await queryClient.invalidateQueries()
    },
  })
}

export function useReorderMutation(qid: string) {
  const canaryClient = useCanaryClient()
  const queryClient = useQueryClient()
  const userQueries = getUserQueries(canaryClient)
  const cartQueries = getCartQueries(canaryClient)

  return useMutation({
    mutationFn: () => canaryClient.reorder(qid),
    onSuccess: async (newCart) => {
      // The new cart becomes the user's active cart, so we can update a few cache entries
      // automatically to avoid waiting on all the refetches.
      queryClient.setQueryData(
        userQueries.detail().queryKey,
        (previousUser) => {
          if (!previousUser) return
          return { ...previousUser, activeCartQid: newCart.qid }
        },
      )
      queryClient.setQueryData(
        cartQueries.detail(newCart.qid).queryKey,
        newCart,
      )

      await queryClient.invalidateQueries()
    },
  })
}
