import type { BuyerCurrency, Country, Statistics } from '@qogita/canary-types'

import COUNTRIES from './data/countries.json'
import COUNTRY_AREAS from './data/countryAreas.json'

export type CountryArea = { areaCode: string; areaName: string }

export const countries = COUNTRIES as {
  name: string
  countryCode: Country
  callingCode: string[]
}[]

export const countriesWithCodes = countries.flatMap((country) => {
  if (Array.isArray(country.callingCode)) {
    return country.callingCode.map((code) => ({
      ...country,
      callingCode: code,
    }))
  }

  return {
    ...country,
    callingCode: country.callingCode,
  }
})

export const getAllowedCallingCodes = (info: Statistics | undefined) => {
  return info?.allowedPhoneCountries
    ? countriesWithCodes.filter((country) =>
        info.allowedPhoneCountries.includes(country.countryCode),
      )
    : countriesWithCodes
}

export const countryAreas = COUNTRY_AREAS as Record<
  Country,
  { isRequired: boolean; countryAreas: CountryArea[] }
>

/**
 * @description Tells that a country has country areas or not for the provided country code
 * @param countryCode ISO 3166-1 alpha-2 code of the country
 * @returns `true` when the country has country areas
 */
export const hasCountryAreas = (countryCode: Country): boolean => {
  return Boolean(countryAreas[countryCode])
}

/**
 * @description Tells that a country requires country area field or not for the provided country code
 * @param countryCode ISO 3166-1 alpha-2 code of the country
 * @returns `true` when the country requires a country area field
 */
export const isCountryAreaRequired = (countryCode: Country): boolean => {
  return Boolean(countryAreas[countryCode]?.['isRequired'])
}

/**
 * @description Returns with the full name of the country with the provided country code
 * @param countryCode ISO 3166-1 alpha-2 code of the country
 * @returns Full name of the country
 */
export const getCountryName = (countryCode: Country): string => {
  const country = countries.find(
    (country) => country.countryCode === countryCode,
  )
  return country ? country.name : ''
}

export const ALL_COUNTRY_CODES = countries.map(
  (country) => country.countryCode,
) as [Country, ...Country[]]

export const ALL_CALLING_CODES = countries.flatMap(
  (country) => country.callingCode,
)

/**
 * @description Returns with the country areas of the provided country
 * @param countryCode ISO 3166-1 alpha-2 code of the country
 * @returns An array of country areas
 */
export const getCountryAreas = (countryCode: Country): Array<CountryArea> => {
  return countryAreas[countryCode]?.['countryAreas'] ?? []
}

/**
 * @description function to determine validity of country code string
 * @param countryCode
 * @returns boolean to determine valid country code type
 */
export const isValidCountryCode = (
  countryCode: string | undefined,
): countryCode is Country => {
  return ALL_COUNTRY_CODES.includes(countryCode as Country)
}

/**
 * @description map to return currency based on country code
 * @param countryCode
 * @returns currency string
 */
export const getCurrencyFromCountryCode = (
  countryCode: Country,
): BuyerCurrency => {
  switch (countryCode) {
    case 'GB':
      return 'GBP'
    case 'US':
      return 'USD'
    default:
      return 'EUR'
  }
}

export type CountryDescriptor = {
  [key: string]: unknown
  name: string
  callingCode: string
}
