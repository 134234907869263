import { storyblokEditable } from '@storyblok/react'
import React from 'react'

import { CmsPanelErrorBoundary } from '#components/cms/CmsPanelErrorBoundary'
import { useAuthentication } from '#contexts/Authentication'
import { BannerPanelStoryblok } from '#types/storyblok-component-types'

import { Anchor } from './Anchor'

const BannerPanelComponent = ({ blok }: { blok: BannerPanelStoryblok }) => {
  const { isAuthenticated } = useAuthentication()
  const title =
    isAuthenticated && blok.authenticatedTitle
      ? blok.authenticatedTitle
      : blok.anonymousTitle
  const description =
    isAuthenticated && blok.authenticatedDescription
      ? blok.authenticatedDescription
      : blok.anonymousDescription
  const [cta] =
    isAuthenticated && blok.authenticatedCta
      ? blok.authenticatedCta
      : blok.anonymousCta

  return (
    <div
      {...storyblokEditable(blok)}
      className="bg-marketing-primary-dark flex justify-center px-4 py-24"
    >
      <div className="flex max-w-5xl flex-col items-center justify-center gap-6 text-balance text-center text-white">
        <h2 className="q-text-header-4xl">{title}</h2>
        {description ? (
          <p className="q-text-header-3xl max-w-2xl text-gray-200">
            {description}
          </p>
        ) : null}
        {cta ? (
          <Anchor
            blok={cta}
            className="w-full md:w-auto"
            color="primaryInverse"
            appearance="contained"
            size="medium"
          />
        ) : null}
      </div>
    </div>
  )
}

export const BannerPanel = ({ blok }: { blok: BannerPanelStoryblok }) => {
  return (
    <CmsPanelErrorBoundary>
      <BannerPanelComponent blok={blok} />
    </CmsPanelErrorBoundary>
  )
}
