import { ArrowRightIcon } from '@heroicons/react/20/solid'
import { useCanaryClient } from '@qogita/canary-client'
import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { useQuery } from '@tanstack/react-query'
import Image from 'next/image'
import Link from 'next/link'
import { getVariantQueries } from 'src/api/variant-queries'

import { CmsPanelErrorBoundary } from '#components/cms/CmsPanelErrorBoundary'
import { CenteredContent } from '#components/Marketing/CenteredContent'
import { useLocalisationPreferences } from '#hooks/api/useLocalisationPreferences'
import { getNumberWithThousandsSeparator } from '#lib/number'
import {
  CategoriesPanelStoryblok,
  CategoryCardStoryblok,
} from '#types/storyblok-component-types'

import { Anchor } from './Anchor'
import { storyblokImageLoader } from './storyblokImageLoader'

const CategoryCardComponent = ({ blok }: { blok: CategoryCardStoryblok }) => {
  const { country } = useLocalisationPreferences()

  const categorySlugs = blok.slug.split('/').filter(Boolean)

  const canaryClient = useCanaryClient()
  const variantQueries = getVariantQueries(canaryClient)
  const { data } = useQuery(
    variantQueries.search({
      categories: categorySlugs,
      country,
    }),
  )

  return (
    <Link
      {...storyblokEditable(blok)}
      href={blok.link.url}
      className="h-full w-full"
    >
      <div className="w-full overflow-hidden rounded-lg text-gray-900">
        <div className="relative h-72 w-full">
          <Image
            loader={storyblokImageLoader}
            src={blok.image.filename}
            alt={blok.image.alt ?? ''}
            fill
            className="object-cover"
          />
        </div>
        <div className="py-4.5 bg-gray-50 px-8">
          <div className="q-text-body-base-bold mb-2 text-gray-700">
            {blok.name}
          </div>
          <div className="flex flex-row">
            <div className="q-text-body-base">
              {getNumberWithThousandsSeparator(data?.count ?? 0)} products
            </div>
            <ArrowRightIcon className="ml-auto h-6 w-6" />
          </div>
        </div>
      </div>
    </Link>
  )
}
export const CategoryCard = ({ blok }: { blok: CategoryCardStoryblok }) => {
  return (
    <CmsPanelErrorBoundary>
      <CategoryCardComponent blok={blok} />
    </CmsPanelErrorBoundary>
  )
}

const CategoriesPanelComponent = ({
  blok,
}: {
  blok: CategoriesPanelStoryblok
}) => {
  const [cta] = blok.cta
  return (
    <div {...storyblokEditable(blok)}>
      <CenteredContent>
        <div className="flex flex-col py-8 md:py-12">
          <h2 className="q-text-header-4xl mb-8 self-center text-balance">
            {blok.title}
          </h2>
          <ul className="mb-8 grid grid-cols-1 gap-8 md:grid-cols-3">
            {blok.categoryCards.map((category) => (
              <li key={category._uid}>
                <StoryblokComponent blok={category} />
              </li>
            ))}
          </ul>
          <div className="flex w-full justify-center">
            {cta ? (
              <Anchor
                color="primary"
                appearance="contained"
                size="medium"
                className="w-full md:w-auto"
                blok={cta}
              />
            ) : null}
          </div>
        </div>
      </CenteredContent>
    </div>
  )
}
export const CategoriesPanel = ({
  blok,
}: {
  blok: CategoriesPanelStoryblok
}) => {
  return (
    <CmsPanelErrorBoundary>
      <CategoriesPanelComponent blok={blok} />
    </CmsPanelErrorBoundary>
  )
}
